import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Toast = (props) => {
  const {
    show,
    error,
    message,
  } = props;

  return (
    <div className={classnames('toast', { 'fade-in': show }, error ? 'toast-error' : 'toast-success')}>
      <p>
        {message}
      </p>
    </div>
  );
};

Toast.defaultProps = {
  error: false,
  message: '',
};

Toast.propTypes = {
  show: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  message: PropTypes.string,
};

export default Toast;

const axios = require('axios');

const createCSV = (rows, filename) => {
  let csvContent = 'data:text/csv;charset=utf-8,';

  rows.forEach((rowArray) => {
    const row = rowArray.join(',');
    csvContent = csvContent.concat(`${row}\r\n`);
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a'); // eslint-disable-line no-undef
  link.setAttribute('href', encodedUri);
  link.setAttribute('target', '_blank');
  link.setAttribute('download', filename);
  document.body.appendChild(link); // eslint-disable-line no-undef

  link.click();
};

const createRoutesReport = (routificRes, customerMap) => {
  const { solution } = routificRes.output;
  const unservedCount = routificRes.output.num_unserved;
  const rows = [[]];

  if (unservedCount > 0) {
    rows.push([`Routes With Errors: ${unservedCount}`]);

    const unservedReasons = routificRes.output.unserved;

    Object.keys(unservedReasons).forEach((key) => {
      rows.push(key, [unservedReasons[key]]);
    });
  } else {
    rows.push(['All Routes Created Successfully']);
  }

  rows.push(['']);
  rows.push(['Routes by Driver']);
  rows.push(['Customer Name', 'Customer Email', 'Customer Phone', 'Address']);

  Object.keys(solution).forEach((key) => {
    rows.push([key]);

    const routes = solution[key];
    routes.forEach((location) => {
      const customer = customerMap[location.location_id];

      if (customer !== undefined) {
        // eslint-disable-next-line no-useless-escape
        rows.push([customer.name, customer.email, customer.phone, `"\""${location.location_name}"\""`]);
      } else {
        rows.push(['', '', '', location.location_name]);
      }
    });
  });

  createCSV(rows, 'Delivery Routes.csv');
};

const createRoutificRequest = (coordinates, selectedDrivers, cb) => {
  const visits = {};
  const customerMapToVisit = {};
  const fleet = {};

  coordinates.forEach((coordinate, index) => {
    visits[`order_${index}`] = {
      location: {
        name: coordinate.name,
        lat: coordinate.lat,
        lng: coordinate.lng,
      },
    };

    customerMapToVisit[`order_${index}`] = coordinate.customer;
  });

  selectedDrivers.forEach((driver, index) => {
    fleet[driver.name] = {
      start_location: {
        id: index,
        name: `${driver.address.address1} ${driver.address.city} ${driver.address.state} ${driver.address.zip}`,
        lat: driver.coordinates.latitude,
        lng: driver.coordinates.longitude,
      },
    };
  });

  const routificReq = {
    visits,
    fleet,
  };

  axios.post('/create-routific-req', { routificReq })
    .then((res) => {
      const jobId = res.data;

      const intervalId = setInterval(() => {
        axios.get(`/routific-job?id=${jobId}`)
          .then((routificRes) => {
            if (routificRes.data.status === 'finished') {
              clearInterval(intervalId);

              createRoutesReport(routificRes.data, customerMapToVisit);

              if (cb !== null && typeof cb === 'function') {
                cb();
              }
            }
          })
          .catch(() => {
            if (cb !== null && typeof cb === 'function') {
              cb('An error occured while waiting for the Routific request to finish.');
            }
          });
      }, 1000);
    })
    .catch(() => {
      if (cb !== null && typeof cb === 'function') {
        cb('An error occured while creating the routific request.');
      }
    });
};

export default function generateRoutes(subscriptions, customersArr, addressesArr,
  standardOrders, pickupLocations, selectedDrivers, cb) {
  const deliveryAddresses = [];
  const usedCustomerIds = {};
  const customers = {};
  const addresses = {};

  for (let i = 0; i < customersArr.length; i += 1) {
    customers[customersArr[i].id] = customersArr[i];
  }

  for (let i = 0; i < addressesArr.length; i += 1) {
    addresses[addressesArr[i].id] = addressesArr[i];
  }

  subscriptions.forEach((subscription) => {
    const address = addresses[subscription.address_id];
    const customer = customers[subscription.customer_id];

    if (address.original_shipping_lines.length > 0
      && address.original_shipping_lines[0].title.toString().toLowerCase().indexOf('delivery') > -1) {
      deliveryAddresses.push({
        customer: {
          name: `${address.first_name} ${address.last_name}`,
          email: customer.email,
          phone: address.phone,
        },
        address: `${address.address1},${address.address2},${address.city},${address.province},${address.zip}`,
      });
    }

    usedCustomerIds[customer.shopify_customer_id] = true;
  });

  standardOrders.forEach((order) => {
    if (!usedCustomerIds[order.customer.id]) {
      if (order.shipping_lines[0] && order.shipping_lines[0].title.toString().toLowerCase().indexOf('delivery') > -1) {
        const address = order.shipping_address;
        deliveryAddresses.push({
          customer: {
            name: `${order.shipping_address.first_name} ${order.shipping_address.last_name}`,
            email: order.customer.email,
            phone: order.shipping_address.phone,
          },
          address: `${address.address1},${address.address2},${address.city},${address.province},${address.zip}`,
        });
      }
    }
  });

  axios.post('/bulk-coordinates', { addresses: deliveryAddresses })
    .then((res) => {
      const coordinates = res.data;

      pickupLocations.forEach((location) => {
        if (location.name.toString().toLowerCase().indexOf('mealdealers') === -1) {
          coordinates.push({
            name: location.name,
            lat: location.coordinates.latitude,
            lng: location.coordinates.longitude,
          });
        }
      });

      createRoutificRequest(coordinates, selectedDrivers, cb);
    })
    .catch(() => {
      if (cb !== null && typeof cb === 'function') {
        cb('An error occured while retrieving coordinates for delivery addresses.');
      }
    });
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

class AddressForm extends Component {
  state = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    longitude: '',
    latitude: '',
    name: '',
    id: 0,
    disableSubmit: false,
    // eslint-disable-next-line react/no-unused-state
    selectedId: -1,
  };

  onSubmit = (event) => {
    event.preventDefault();

    const {
      longitude,
      latitude,
      id,
      name,
      address1,
      address2,
      city,
      state,
      zip,
    } = this.state;
    const {
      toast,
      onSubmit,
    } = this.props;

    if (longitude === '' || latitude === '') {
      toast(true, 'You Need to Get The Coordinates for the Address');
      return;
    }

    const obj = {
      id,
      name,
      address: {
        address1,
        address2,
        city,
        state,
        zip,
      },
      coordinates: {
        latitude,
        longitude,
      },
    };

    onSubmit(obj);
  }

  static getDerivedStateFromProps(props, state) {
    const { formAddress } = props;

    if (formAddress === null || formAddress === undefined) {
      return { disableSubmit: true };
    }

    if (props.selectedId !== state.selectedId) {
      return {
        address1: formAddress.address1,
        address2: formAddress.address2,
        city: formAddress.city,
        state: formAddress.state,
        zip: formAddress.zip,
        longitude: formAddress.longitude,
        latitude: formAddress.latitude,
        name: formAddress.name,
        id: props.selectedId,
        disableSubmit: false,
        selectedId: props.selectedId,
      };
    }

    return null;
  }

  getCoordinates = () => {
    const {
      address1,
      address2,
      city,
      state,
      zip,
    } = this.state;
    const {
      toast,
    } = this.props;

    const address = `${address1},${address2},${city},${state},${zip}`;

    axios.get(`/coordinates?address=${address}`)
      .then((res) => {
        this.setState({
          longitude: res.data.lng,
          latitude: res.data.lat,
        });
      })
      .catch(() => {
        toast(true, 'An error occured while retrieving the latitude and longitude.');
      });
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const {
      name,
      address1,
      address2,
      city,
      state,
      zip,
      latitude,
      longitude,
      disableSubmit,
    } = this.state;
    const { disableName } = this.props;

    return (
      <form className="address-form" onSubmit={this.onSubmit}>
        <div className="md-grid">
          <div className="md-row">
            <div className="md-col-xs-12">
              <input type="text" placeholder="Name" name="name" value={name} onChange={this.handleInputChange} disabled={disableName} required />
            </div>
          </div>

          <div className="md-row">
            <div className="md-col-xs-6">
              <input text="text" placeholder="Address 1" name="address1" onChange={this.handleInputChange} value={address1} required />
            </div>

            <div className="md-col-xs-6">
              <span>
                {`Latitude: ${latitude}`}
              </span>
            </div>
          </div>

          <div className="md-row">
            <div className="md-col-xs-6">
              <input text="text" placeholder="Address 2" name="address2" onChange={this.handleInputChange} value={address2} />
            </div>

            <div className="md-col-xs-6">
              <span>
                {`Longitude: ${longitude}`}
              </span>
            </div>
          </div>

          <div className="md-row">
            <div className="md-col-xs-6">
              <input text="text" placeholder="City" name="city" onChange={this.handleInputChange} value={city} required />
            </div>
          </div>

          <div className="md-row">
            <div className="md-col-xs-6">
              <input text="text" placeholder="State" name="state" onChange={this.handleInputChange} value={state} required />
            </div>
          </div>

          <div className="md-row">
            <div className="md-col-xs-6">
              <input text="text" placeholder="Zip" name="zip" onChange={this.handleInputChange} value={zip} required />
            </div>
          </div>

          <div className="command-bar">
            <button type="button" className="btn" onClick={this.getCoordinates}>
              {'Get Cooridinates for Address'}
            </button>

            <button type="submit" className="btn" disabled={disableSubmit} title={disableSubmit ? 'select a location from the list first' : ''}>
              {'Save'}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

AddressForm.propTypes = {
  toast: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableName: PropTypes.bool.isRequired,
};

export default AddressForm;

import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AddressForm from './AddressForm';

const PickupLocation = (props) => {
  const { warning, location, onClick } = props;

  return (
    // eslint-disable-next-line quote-props
    <div className={classnames('config-item', { 'warning': warning })} onClick={() => onClick(location.id)}>
      {location.name}
    </div>
  );
};

class PickupLocationConfig extends Component {
  state = {
    locations: [],
    selectedLocation: null,
    mappedAddresses: [],
    formAddress: null,
  };

  componentDidMount = () => {
    const { toast } = this.props;

    axios.get('/shipping-zones')
      .then((res) => {
        const zones = res.data.shipping_zones[0].price_based_shipping_rates;
        const locations = [];

        for (let i = 0; i < zones.length; i += 1) {
          if (zones[i].name.toLowerCase().indexOf('pickup') > -1) {
            locations.push(zones[i]);
          }
        }

        this.setState({ locations });
      })
      .catch(() => {
        toast(true, 'An error occured while retrieving the shipping zones.');
      });

    axios.get('/pickup-addresses')
      .then((res) => {
        this.setState({ mappedAddresses: res.data.addresses });
      })
      .catch(() => {
        toast(true, 'An error occured while retrieiving the pickup address mappings.');
      });
  }

  locationClicked = (id) => {
    const { locations, mappedAddresses } = this.state;
    const location = locations.find(element => element.id === id);
    const mappedLocation = mappedAddresses.find(element => element.zoneId === location.id);
    const formAddress = {
      address1: mappedLocation === undefined ? '' : mappedLocation.address.address1,
      address2: mappedLocation === undefined ? '' : mappedLocation.address.address2,
      city: mappedLocation === undefined ? '' : mappedLocation.address.city,
      state: mappedLocation === undefined ? '' : mappedLocation.address.state,
      zip: mappedLocation === undefined ? '' : mappedLocation.address.zip,
      latitude: mappedLocation === undefined ? '' : mappedLocation.coordinates.latitude,
      longitude: mappedLocation === undefined ? '' : mappedLocation.coordinates.longitude,
      name: location.name,
      id: mappedLocation === undefined ? 0 : mappedLocation.id,
    };

    this.setState({
      selectedLocation: location,
      formAddress,
    });
  }

  submitForm = (obj) => {
    const { selectedLocation, mappedAddresses, formAddress } = this.state;
    const { toast } = this.props;
    const tempObj = Object.assign({}, obj);

    tempObj.zoneId = selectedLocation.id;

    if (tempObj.id === 0) {
      axios.post('/pickup-address', { tempObj, key: tempObj.zoneId })
        .then((res) => {
          tempObj.id = res.data.id;

          const formAddressCopy = Object.assign({}, formAddress);
          formAddressCopy.id = tempObj.id;

          const mappedAddressesCopy = mappedAddresses.slice();
          mappedAddressesCopy.push(tempObj);

          this.setState({ mappedAddresses: mappedAddressesCopy, formAddress: formAddressCopy });
        })
        .catch(() => {
          toast(true, 'An error occured while adding a pickup address mapping.');
        });
    } else {
      axios.put('/pickup-address', tempObj)
        .then((res) => {
          toast(false, res.data.msg);

          const index = mappedAddresses.findIndex(element => element.id === tempObj.id);
          const mappedAddressesCopy = mappedAddresses.slice();

          mappedAddressesCopy[index] = tempObj;

          this.setState({ mappedAddresses: mappedAddressesCopy });
        })
        .catch(() => {
          toast(true, 'An error occured while updating the driver.');
        });
    }
  }

  render() {
    const { mappedAddresses, locations, formAddress } = this.state;
    const { toast } = this.props;

    return (
      <div className="md-grid">
        <div className="md-row">
          <div className="md-col-xs-4">
            <p className="text-center">
              {'Locations in list highlighted in red do not have an address mapped to them. Please configure one for routing.'}
            </p>

            <div className="config-list">
              {locations.map((location) => {
                const mappedLocation = mappedAddresses.find(element => (
                  element.zoneId === location.id
                ));

                return (
                  <PickupLocation
                    key={location.id}
                    location={location}
                    onClick={this.locationClicked}
                    warning={mappedLocation === undefined}
                  />
                );
              })}
            </div>
          </div>

          <div className="md-col-xs-8">
            <AddressForm
              onSubmit={this.submitForm}
              disableName
              formAddress={formAddress}
              selectedId={formAddress !== null ? formAddress.id : null}
              toast={toast}
            />
          </div>
        </div>
      </div>
    );
  }
}

PickupLocation.propTypes = {
  warning: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

PickupLocationConfig.propTypes = {
  toast: PropTypes.func.isRequired,
};

export default PickupLocationConfig;

import React from 'react';
import PropTypes from 'prop-types';

const ConfirmDelete = (props) => {
  const {
    header,
    message,
    cancelHandler,
    confirmHandler,
  } = props;

  return (
    <div className="overlay">
      <div className="modal">

        <h1>
          {header}
        </h1>

        <h4>
          {message}
        </h4>

        <div className="action-buttons">
          <button type="button" className="btn" onClick={cancelHandler}>
            {'Cancel'}
          </button>

          <button type="button" className="btn" onClick={confirmHandler}>
            {'Confrim Delete'}
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmDelete.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  confirmHandler: PropTypes.func.isRequired,
};

export default ConfirmDelete;

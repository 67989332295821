import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import Routes from './Routes';
import './App.scss';

const App = () => (
  <div>
    <nav>
      <div className="navbar">
        <ul>
          <li>
            <Link to="/routing">Routing</Link>
          </li>

          <li>
            <Link to="/reporting">Reporting</Link>
          </li>

          <li>
            <Link to="/config">Config</Link>
          </li>
        </ul>
      </div>
    </nav>

    <main>
      <Routes />
    </main>
  </div>
);

export default withRouter(App);

import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Confirm from './Confirm';
import AddressForm from './AddressForm';

const Driver = (props) => {
  const {
    driverClick,
    driver,
    deleteClick,
  } = props;

  return (
    <div className="inline-icon config-item" onClick={() => driverClick(driver.id)}>
      <p>
        {driver.name}
      </p>
      <i className="fa fa-trash" onClick={() => deleteClick(driver.name)} />
    </div>
  );
};

class DriverConfig extends Component {
  state = {
    drivers: [],
    addMode: false,
    deleteDriverMode: false,
    confirmMessage: '',
    selectedDriver: null,
    formAddress: null,
  };

  componentDidMount = () => {
    const { toast } = this.props;

    axios.get('/drivers')
      .then((res) => {
        this.setState({ drivers: res.data.drivers });
      })
      .catch(() => {
        toast(true, 'An error occured while loading the drivers.');
      });
  }

  submitForm = (obj) => {
    const { toast } = this.props;
    const { addMode, drivers } = this.state;

    // TODO: use immutability helpers instead
    const driversCopy = drivers.slice();

    if (addMode) {
      axios.post('/driver', obj)
        .then((res) => {
          toast(false, 'Driver successfully added!');

          const tempObj = Object.assign({}, obj);
          tempObj.id = res.data.id;

          driversCopy.push(tempObj);

          this.setState({
            addMode: false,
            drivers: driversCopy,
          });
        })
        .catch(() => {
          toast(true, 'An error occured while adding the driver.');
        });
    } else {
      axios.put('/driver', obj)
        .then((res) => {
          toast(false, res.data.msg);

          const index = drivers.findIndex(element => element.id === obj.id);

          driversCopy[index] = obj;

          this.setState({ drivers: driversCopy });
        })
        .catch(() => {
          toast(true, 'An error occured while updating the driver.');
        });
    }
  }

  deleteHandler = (name) => {
    this.setState({
      deleteDriverMode: true,
      confirmMessage: `Are you sure you want to delete driver: ${name}?`,
    });
  }

  driverClick = (id) => {
    const { drivers } = this.state;
    const driver = drivers.find(element => element.id === id);
    const formAddress = {
      address1: driver.address.address1,
      address2: driver.address.address2,
      city: driver.address.city,
      state: driver.address.state,
      zip: driver.address.zip,
      latitude: driver.coordinates.latitude,
      longitude: driver.coordinates.longitude,
      name: driver.name,
      id: driver.id,
    };

    this.setState({
      selectedDriver: driver,
      formAddress,
      addMode: false,
    });
  }

  confirmDelete = () => {
    const { selectedDriver, drivers } = this.state;
    const { toast } = this.props;

    axios.delete('/driver', { data: { id: selectedDriver.id } })
      .then((res) => {
        toast(false, res.data.msg);

        const index = drivers.findIndex(element => element.id === selectedDriver.id);

        const driversCopy = drivers.slice();
        driversCopy.splice(index, 1);
        this.setState({
          drivers: driversCopy,
          deleteDriverMode: false,
        });

        this.clearForm();
      })
      .catch(() => {
        toast(true, 'An error occured while deleting the driver.');

        this.setState({ deleteDriverMode: false });
      });
  }

  addNewDriver = () => {
    this.clearForm();
    this.setState({ addMode: true, selectedDriver: null });
  }

  clearForm = () => {
    const formAddress = {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      latitude: '',
      longitude: '',
      name: '',
      id: 0,
    };

    this.setState({ formAddress });
  }

  render() {
    const {
      drivers,
      addMode,
      formAddress,
      deleteDriverMode,
      confirmMessage,
    } = this.state;
    const { toast } = this.props;

    return (
      <div>
        <div className="command-bar">
          <button type="button" className="btn" onClick={this.addNewDriver}>
            {'Add Driver'}
          </button>
        </div>

        <div className="md-grid">
          <div className="md-row">
            <div className="md-col-xs-4">
              <div className="config-list">
                {drivers.map(driver => (
                  <Driver
                    key={driver.id}
                    driver={driver}
                    deleteClick={this.deleteHandler}
                    driverClick={this.driverClick}
                  />
                ))}
              </div>
            </div>

            <div className="md-col-xs-8">
              {addMode ? 'Enter details for new driver below: ' : null}

              <AddressForm
                onSubmit={this.submitForm}
                disableName={false}
                formAddress={formAddress}
                selectedId={formAddress !== null ? formAddress.id : null}
                toast={toast}
              />
            </div>
          </div>
        </div>

        {deleteDriverMode ? (
          <Confirm
            confirmHandler={this.confirmDelete}
            cancelHandler={() => this.setState({ deleteDriverMode: false })}
            header="Confirm Delete"
            message={confirmMessage}
          />
        ) : null}
      </div>
    );
  }
}

Driver.propTypes = {
  driverClick: PropTypes.func.isRequired,
  driver: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  deleteClick: PropTypes.func.isRequired,
};

DriverConfig.propTypes = {
  toast: PropTypes.func.isRequired,
};

export default DriverConfig;

import axios from 'axios';

import { ShopifyOrder, MealSelection, ShopifyProduct } from './types';

function compareByCustomerName(a: ShopifyOrder, b: ShopifyOrder) {
  const customerA = (a.customer_name || '').trim().toUpperCase();
  const customerB = (b.customer_name || '').trim().toUpperCase();
  let comparison = 0;

  if (customerA > customerB) {
    comparison = 1;
  } if (customerB > customerA) {
    comparison = -1;
  }

  return comparison;
}

function createCSV(rows: (string | number)[][], filename: string) {
  let csvContent = 'data:text/csv;charset=utf-8,';

  rows.forEach((rowArray) => {
    const formattedArray = rowArray.map((elem) => elem.toString().replace(/,/g, ' '));
    const row = formattedArray.join(',');

    csvContent = csvContent.concat(`${row}\r\n`);
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a'); // eslint-disable-line no-undef
  link.setAttribute('href', encodedUri);
  link.setAttribute('target', '_blank');
  link.setAttribute('download', filename);
  document.body.appendChild(link); // eslint-disable-line no-undef

  link.click();
}

export function processStandardOrdersReport(standardOrders: ShopifyOrder[]) {
  const rows: string[][] = [];
  const orderErrors: number[] = [];

  let ordersCopy = [...standardOrders];

  for (let i = 0; i < standardOrders.length; i += 1) {
    const { customer } = standardOrders[i];

    if (customer) {
      ordersCopy[i].customer_name = `${customer.last_name.trim()}, ${customer.first_name.trim()}`;
    }
  }

  ordersCopy = standardOrders.sort(compareByCustomerName);

  for (let i = 0; i < ordersCopy.length; i += 1) {
    const order = ordersCopy[i];

    try {
      let firstRow = true;
      let mealQuantity = 0;

      order.line_items.forEach((lineItem) => {
        let isMealPortion = false;

        lineItem.properties.forEach((property) => {
          if (property.name === '_MealPortion') {
            isMealPortion = true;
          }
        });

        if (isMealPortion) {
          return;
        }

        const row: string[] = [];

        mealQuantity += lineItem.quantity;

        if (firstRow) {
          const firstName = order.shipping_address.first_name.trim();
          const lastName = order.shipping_address.last_name.trim();
          row.push(`${firstName} ${lastName}`);

          firstRow = false;
        } else {
          row.push('');
        }

        row.push(`${lineItem.title} - ${lineItem.variant_title}`);

        const properties: string[] = [];
        let additionalInfo = '';

        // 4788406845549 - New Meal Subscription
        // 61883088924 - CYOM
        // 75936727068, 75934760988 - Old Plans

        if (lineItem.product_id !== 75936727068
        && lineItem.product_id !== 75934760988
        && lineItem.product_id !== 4788406845549
        && lineItem.product_id !== 61883088924) {
          lineItem.properties.forEach((property: any) => {
            if (property.name.substring(0, 1) !== '_'
            && property.name !== 'shipping_interval_frequency'
            && property.name !== 'shipping_interval_unit_type'
            && property.value !== null
            && property.name !== 'isMeal' && property.name !== 'subscriptionEligible') {
              properties.push(`${property.name}: ${property.value.toString().replace(', ', ' - ')}`);
            }
          });
        }

        properties.forEach((property, index) => {
          if (index === properties.length - 1) {
            additionalInfo = additionalInfo.concat(`${property}`);
          } else if (property !== '') {
            additionalInfo = additionalInfo.concat(`${property}\r\n`);
          }
        });

        row.push(lineItem.quantity.toString());
        row.push(`"\""${additionalInfo.split('#').join('')}"\""`); // eslint-disable-line no-useless-escape

        rows.push(row);
      });

      rows.push([`Total meal count for ${order.shipping_address.first_name} ${order.shipping_address.last_name}`, mealQuantity.toString()]);
    } catch (err) {
      orderErrors.push(order.id);
    }
  }

  rows.unshift(['Name', 'Meal', 'Quantity', 'Additional Info']);

  createCSV(rows, 'Orders Report.csv');

  return { rows, orderErrors };
}

type QuantityMap = { [index: number]: { quantity: number; product: string } };

function createCSVRowsForQuantitiesReport(quantityMap: QuantityMap, orderErrors: number[]) {
  const rows: string[][] = [];

  Object.keys(quantityMap).forEach((key) => {
    const row = [];

    row.push(quantityMap[parseInt(key, 10)].product);
    row.push(quantityMap[parseInt(key, 10)].quantity.toString());

    rows.push(row);
  });

  rows.sort((a, b) => {
    if (a[0] > b[0]) {
      return 1;
    }

    if (b[0] > a[0]) {
      return -1;
    }

    return 0;
  });

  rows.unshift(['Meal', 'Quantity']);

  createCSV(rows, 'Quantities Report.csv');

  return { rows, orderErrors };
}

function getTitle(products: ShopifyProduct[], variantId: number) {
  for (let i = 0; i < products.length; i += 1) {
    for (let j = 0; j < products[i].variants.length; j += 1) {
      if (products[i].variants[j].id === variantId) {
        return `${products[i].title} - ${products[i].variants[j].title}`;
      }
    }
  }

  return '';
}

export function processQuantitiesReport(standardOrders: ShopifyOrder[]) {
  const quantityMap: QuantityMap = {};
  const orderErrors: number[] = [];

  for (let i = 0; i < standardOrders.length; i += 1) {
    const order = standardOrders[i];

    try {
      order.line_items.forEach((lineItem) => {
        let isMealPortion = false;
        let isSubscriptionItem = false;

        lineItem.properties.forEach((property) => {
          if (property.name === '_MealPortion') {
            isMealPortion = true;
          } else if (property.name === 'shipping_interval_frequency') {
            isSubscriptionItem = true;
          }
        });

        if (isMealPortion || isSubscriptionItem) {
          return;
        }

        if (lineItem.variant_id) {
          if (quantityMap[lineItem.variant_id] === undefined) {
            quantityMap[lineItem.variant_id] = {
              quantity: lineItem.quantity,
              product: lineItem.name,
            };
          } else {
            quantityMap[lineItem.variant_id].quantity += lineItem.quantity;
          }
        }
      });
    } catch (err) {
      orderErrors.push(order.id);
    }
  }

  const productIds: number[] = [];
  const tempMap: { [index: number]: number } = {};

  for (let i = 0; i < standardOrders.length; i += 1) {
    for (let j = 0; j < standardOrders[i].line_items.length; j += 1) {
      const { price, product_id: productId, properties } = standardOrders[i].line_items[j];

      if (productId === 4788406845549 && parseFloat(price) !== 1) {
        const mealSelectionsProp = properties.find((elem) => elem.name === 'mealSelections');

        if (mealSelectionsProp) {
          const mealSelections: MealSelection[] = JSON.parse(mealSelectionsProp.value);

          for (let k = 0; k < mealSelections.length; k += 1) {
            const selection = mealSelections[k];

            if (selection.mealType === 'standard') {
              if (productIds.indexOf(selection.productId) === -1) {
                productIds.push(selection.productId);
              }

              if (tempMap[selection.variantId] === undefined) {
                tempMap[selection.variantId] = selection.quantity;
              } else {
                tempMap[selection.variantId] += selection.quantity;
              }
            } else if (selection.mealType === 'custom') {
              for (let l = 0; l < selection.productIds.length; l += 1) {
                if (productIds.indexOf(selection.productIds[l]) === -1) {
                  productIds.push(selection.productIds[l]);
                }

                if (tempMap[selection.variantIds[l]] === undefined) {
                  tempMap[selection.variantIds[l]] = selection.quantity;
                } else {
                  tempMap[selection.variantIds[l]] += selection.quantity;
                }
              }
            }
          }
        }
      }
    }
  }

  if (productIds.length > 0) {
    return axios.get(`/products?ids=${productIds}`)
      .then((res) => {
        const keys = Object.keys(tempMap);

        for (let i = 0; i < keys.length; i += 1) {
          const variantId = parseInt(keys[i], 10);

          if (quantityMap[variantId] === undefined) {
            const title = getTitle(res.data.products, variantId);

            quantityMap[variantId] = {
              quantity: tempMap[variantId],
              product: title,
            };
          } else {
            quantityMap[variantId].quantity += tempMap[variantId];
          }
        }

        return createCSVRowsForQuantitiesReport(quantityMap, orderErrors);
      })
      .catch((err) => {
        console.error(err);

        throw new Error('An error occurred while getting the product ids for the quantities report.');
      });
  }

  return createCSVRowsForQuantitiesReport(quantityMap, orderErrors);
}

export async function processCustomizableMealsReport(
  standardOrders: ShopifyOrder[],
  customizableProductIds: number[],
) {
  customizableProductIds.push(4788406845549);

  const customizableMealOrders: ShopifyOrder[] = [];
  const rows: string[][] = [];
  const orderErrors: number[] = [];
  const productIds: number[] = [];

  let ordersCopy = [...standardOrders];

  for (let i = 0; i < standardOrders.length; i += 1) {
    const { customer } = standardOrders[i];

    if (customer) {
      ordersCopy[i].customer_name = `${customer.last_name.trim()}, ${customer.first_name.trim()}`;
    }
  }

  ordersCopy = standardOrders.sort(compareByCustomerName);

  for (let i = 0; i < ordersCopy.length; i += 1) {
    const order = ordersCopy[i];

    for (let j = 0; j < order.line_items.length; j += 1) {
      const { price, product_id: productId, properties } = order.line_items[j];

      if (productId === 4788406845549 && parseFloat(price) !== 1) {
        const mealSelectionsProp = properties.find((elem) => elem.name === 'mealSelections');

        if (mealSelectionsProp) {
          const mealSelections: MealSelection[] = JSON.parse(mealSelectionsProp.value);

          for (let k = 0; k < mealSelections.length; k += 1) {
            const selection = mealSelections[k];

            if (selection.mealType === 'custom') {
              for (let l = 0; l < selection.productIds.length; l += 1) {
                if (productIds.indexOf(selection.productIds[l]) === -1) {
                  productIds.push(selection.productIds[l]);
                }
              }
            } else if (productIds.indexOf(selection.productId) === -1) {
              productIds.push(selection.productId);
            }
          }
        }
      }

      if (productId && customizableProductIds.indexOf(productId) > -1) {
        customizableMealOrders.push(order);

        break;
      }
    }
  }

  try {
    let products: ShopifyProduct[] = [];

    if (productIds.length > 0) {
      const res = await axios.get(`/products?ids=${productIds}`);

      products = res.data.products;
    }

    for (let i = 0; i < customizableMealOrders.length; i += 1) {
      const order = customizableMealOrders[i];

      try {
        let firstRow = true;

        order.line_items.forEach((lineItem) => {
          if (lineItem.product_id && customizableProductIds.indexOf(lineItem.product_id) === -1) {
            return;
          }

          // Subscription
          if (lineItem.product_id === 4788406845549) {
            const mealSelectionsProp = lineItem.properties.find((elem) => elem.name === 'mealSelections');

            if (mealSelectionsProp) {
              const mealSelections: MealSelection[] = JSON.parse(mealSelectionsProp.value);

              for (let k = 0; k < mealSelections.length; k += 1) {
                const selection = mealSelections[k];

                if (selection.mealType === 'custom') {
                  const row: string[] = [];

                  if (firstRow) {
                    const firstName = order.shipping_address.first_name.trim();
                    const lastName = order.shipping_address.last_name.trim();

                    row.push(`${firstName} ${lastName}`);

                    firstRow = false;
                  } else {
                    row.push('');
                  }

                  row.push('Custom Meal');
                  row.push(selection.quantity.toString());

                  for (let l = 0; l < selection.variantIds.length; l += 1) {
                    const title = getTitle(products, selection.variantIds[l]);

                    if (l === 0) {
                      row.push(title);

                      rows.push(row);
                    } else {
                      const infoRow: string[] = ['', '', ''];

                      infoRow.push(title);

                      rows.push(infoRow);
                    }
                  }
                } else {
                  const title = getTitle(products, selection.variantId).split(' - ');
                  let skip = false;

                  if (title.length === 2 && (title[1].trim().startsWith('+0') || title[1] === 'Default Title')) {
                    skip = true;
                  }

                  if (skip === false) {
                    const row: string[] = [];

                    if (firstRow) {
                      const firstName = order.shipping_address.first_name.trim();
                      const lastName = order.shipping_address.last_name.trim();

                      row.push(`${firstName} ${lastName}`);

                      firstRow = false;
                    } else {
                      row.push('');
                    }

                    row.push(title[0].trim());
                    row.push(selection.quantity.toString());
                    row.push(title[1].trim());

                    rows.push(row);
                  }
                }
              }
            }
          } else {
            const row: string[] = [];
            const customizations: string[] = [];
            let isMealPortion = false;

            // Create Your Own Meal
            if (lineItem.product_id === 61883088924) {
              lineItem.properties.forEach((property) => {
                if (property.name.substring(0, 1) !== '_'
                  && property.name !== 'shipping_interval_frequency'
                  && property.name !== 'shipping_interval_unit_type'
                  && property.value !== null
                  && property.name !== 'isMeal' && property.name !== 'subscriptionEligible') {
                  customizations.push(`${property.name}: ${property.value.toString().replace(', ', ' - ')}`);
                }
              });
            } else {
              lineItem.properties.forEach((property) => {
                if (property.name === '_MealPortion') {
                  isMealPortion = true;
                }
              });

              if (lineItem.variant_title.startsWith('+0')) {
                return;
              }

              customizations.push(lineItem.variant_title);
            }

            if (isMealPortion === false) {
              if (firstRow) {
                const firstName = order.shipping_address.first_name.trim();
                const lastName = order.shipping_address.last_name.trim();

                row.push(`${firstName} ${lastName}`);

                firstRow = false;
              } else {
                row.push('');
              }

              row.push(lineItem.title);
              row.push(lineItem.quantity.toString());
              row.push(customizations.length > 0 ? customizations[0] : '');

              rows.push(row);

              for (let j = 1; j < customizations.length; j += 1) {
                const infoRow: string[] = ['', '', ''];

                infoRow.push(customizations[j]);

                rows.push(infoRow);
              }
            }
          }
        });
      } catch (err) {
        orderErrors.push(order.id);
      }
    }

    rows.unshift(['Name', 'Meal', 'Quantity', 'Customization']);

    createCSV(rows, 'Customizations Report.csv');

    return { rows, orderErrors };
  } catch (err) {
    console.error(err);

    throw new Error('An error occurred while getting the product ids for the customizations report.');
  }
}

export async function processSubscribersReport(standardOrders: ShopifyOrder[]) {
  const rows: string[][] = [];
  const orderErrors: number[] = [];
  const productIds: number[] = [];
  const orderIds: number[] = [];

  let ordersCopy = [...standardOrders];

  for (let i = 0; i < standardOrders.length; i += 1) {
    const { customer } = standardOrders[i];

    if (customer) {
      ordersCopy[i].customer_name = `${customer.last_name.trim()}, ${customer.first_name.trim()}`;
    }
  }

  ordersCopy = standardOrders.sort(compareByCustomerName);

  for (let i = 0; i < ordersCopy.length; i += 1) {
    const order = ordersCopy[i];

    for (let j = 0; j < order.line_items.length; j += 1) {
      const { price, product_id: productId, properties } = order.line_items[j];

      if (productId === 4788406845549 && parseFloat(price) !== 1) {
        orderIds.push(order.id);

        const mealSelectionsProp = properties.find((elem) => elem.name === 'mealSelections');

        if (mealSelectionsProp) {
          const mealSelections: MealSelection[] = JSON.parse(mealSelectionsProp.value);

          for (let k = 0; k < mealSelections.length; k += 1) {
            const selection = mealSelections[k];

            if (selection.mealType === 'standard') {
              if (productIds.indexOf(selection.productId) === -1) {
                productIds.push(selection.productId);
              }
            }
          }
        }
      }
    }
  }

  try {
    const res = await axios.get(`/products?ids=${productIds}`);

    for (let i = 0; i < ordersCopy.length; i += 1) {
      const order = ordersCopy[i];

      if (orderIds.indexOf(order.id) > -1) {
        try {
          for (let j = 0; j < order.line_items.length; j += 1) {
            const { price, product_id: productId, properties } = order.line_items[j];

            if (productId === 4788406845549 && parseFloat(price) !== 1) {
              orderIds.push(order.id);

              const mealSelectionsProp = properties.find((elem) => elem.name === 'mealSelections');

              if (mealSelectionsProp) {
                const mealSelections: MealSelection[] = JSON.parse(mealSelectionsProp.value);

                for (let k = 0; k < mealSelections.length; k += 1) {
                  const selection = mealSelections[k];
                  const row: string[] = [];

                  if (k === 0) {
                    const firstName = order.shipping_address.first_name.trim();
                    const lastName = order.shipping_address.last_name.trim();

                    row.push(`${firstName} ${lastName}`);
                  } else {
                    row.push('');
                  }

                  if (selection.mealType === 'standard') {
                    const title = getTitle(res.data.products, selection.variantId);

                    row.push(title);
                    row.push(selection.quantity.toString());
                  } else if (selection.mealType === 'custom') {
                    row.push('Custom Meal');
                    row.push(selection.quantity.toString());
                  }

                  rows.push(row);
                }
              }
            }
          }

          rows.push(['']);
        } catch (err) {
          orderErrors.push(order.id);
        }
      }
    }

    rows.unshift(['Name', 'Meal', 'Quantity']);

    createCSV(rows, 'Subscribers Report.csv');

    return { rows, orderErrors };
  } catch (err) {
    console.error(err);

    throw new Error('An error occurred while getting the product ids for the quantities report.');
  }
}

export function processCustomersReport(standardOrders: ShopifyOrder[]) {
  const rows: string[][] = [];
  const usedAddresses: string[] = [];
  const orderErrors: number[] = [];

  let ordersCopy = [...standardOrders];

  for (let i = 0; i < standardOrders.length; i += 1) {
    const { customer } = standardOrders[i];

    if (customer) {
      ordersCopy[i].customer_name = `${customer.last_name.trim()}, ${customer.first_name.trim()}`;
    }
  }

  ordersCopy = standardOrders.sort(compareByCustomerName);

  for (let i = 0; i < ordersCopy.length; i += 1) {
    const order = ordersCopy[i];

    try {
      const { customer } = order;

      if (customer && usedAddresses.includes(`${customer.id}-${order.shipping_address.address1}`) === false) {
        const row: string[] = [];

        const firstName = order.shipping_address.first_name.trim().replace(',', '');
        const lastName = order.shipping_address.last_name.trim().replace(',', '');
        row.push(`${firstName} ${lastName}`);

        row.push(`"\""${order.shipping_address.address1.split('#').join('')}"\""`); // eslint-disable-line no-useless-escape
        row.push(`"\""${order.shipping_address.address2 ? order.shipping_address.address2.split('#').join('') : ''}"\""`); // eslint-disable-line no-useless-escape
        row.push(order.shipping_address.city);
        row.push(order.shipping_address.province);
        row.push(order.shipping_address.zip);
        row.push(order.shipping_lines.length > 0 ? order.shipping_lines[0].title : '');
        row.push(customer.email);
        row.push(order.shipping_address.phone || '');

        usedAddresses.push(`${customer.id}-${order.shipping_address.address1}`);
        rows.push(row);
      }
    } catch (err) {
      orderErrors.push(order.id);
    }
  }

  rows.unshift(['Name', 'Address 1', 'Address 2', 'City', 'State', 'Zip', 'Shipping Option', 'Email', 'Phone Number']);

  createCSV(rows, 'Customers Report.csv');

  return { rows, orderErrors };
}

import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../components/Loader';
import * as Generator from '../ReportGenerator';
import Toast from '../components/Toast';
import { ShopifyProduct } from '../types';

class Reporting extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    showLoader: false,
    showToast: false,
    reportText: '',
    orderErrors: [],
    subscriptionErrors: [],
    toastError: false,
    toastMessage: '',
  };

  getStandardOrdersReport = () => {
    this.setState({ showLoader: true, orderErrors: [], subscriptionErrors: [] });

    axios.get('/shopify-orders')
      .then((res) => {
        const { rows, orderErrors } = Generator.processStandardOrdersReport(res.data);

        this.setState({ showLoader: false });

        this.setReportText(rows);

        if (orderErrors.length > 0) {
          this.setState({ orderErrors });
        }
      })
      .catch((err) => {
        console.error(err);

        this.setState({ showLoader: false });
        this.toast(true, 'An error occurred while generating the Standard Orders report');
      });
  }

  getQuantityReport = () => {
    this.setState({ showLoader: true, orderErrors: [], subscriptionErrors: [] });

    axios.get('/shopify-orders')
      .then(async (res) => {
        const { rows, orderErrors } = await Generator.processQuantitiesReport(res.data);

        this.setState({ showLoader: false });

        this.setReportText(rows);

        if (orderErrors.length > 0) {
          this.setState({ orderErrors });
        }
      })
      .catch((err) => {
        console.error(err);

        this.setState({ showLoader: false });
        this.toast(true, 'An error occurred while generating the Quantities report');
      });
  }

  getCustomizableMealsReport = () => {
    this.setState({ showLoader: true, orderErrors: [], subscriptionErrors: [] });

    const req1 = axios.get('/shopify-orders');
    const req2 = axios.get('/products/customizable');

    axios.all([req1, req2])
      .then(axios.spread(async (orders, products) => {
        const customizableProductIds: number[] = [];

        products.data.products
          .map((product: ShopifyProduct) => customizableProductIds.push(product.id));

        const { rows, orderErrors } = await Generator.processCustomizableMealsReport(
          orders.data,
          customizableProductIds,
        );

        this.setState({ showLoader: false });

        this.setReportText(rows);

        if (orderErrors.length > 0) {
          this.setState({ orderErrors });
        }
      }))
      .catch((err) => {
        console.error(err);

        this.setState({ showLoader: false });
        this.toast(true, 'An error occurred while generating the Customizable Meals report');
      });
  }

  getSubscribersReport = () => {
    this.setState({ showLoader: true, orderErrors: [], subscriptionErrors: [] });

    axios.get('/shopify-orders')
      .then(async (res) => {
        const { rows, orderErrors } = await Generator.processSubscribersReport(res.data);

        this.setState({ showLoader: false });

        this.setReportText(rows);

        if (orderErrors.length > 0) {
          this.setState({ orderErrors });
        }
      })
      .catch((err) => {
        console.error(err);

        this.setState({ showLoader: false });
        this.toast(true, 'An error occurred while generating the Subscribers report');
      });
  }

  getCustomersReport = () => {
    this.setState({ showLoader: true, orderErrors: [], subscriptionErrors: [] });

    axios.get('/shopify-orders')
      .then((res) => {
        const { rows, orderErrors } = Generator.processCustomersReport(res.data);

        this.setState({ showLoader: false });

        this.setReportText(rows);

        if (orderErrors.length > 0) {
          this.setState({ orderErrors });
        }
      })
      .catch((err) => {
        console.error(err);

        this.setState({ showLoader: false });
        this.toast(true, 'An error occurred while generating the Customers report');
      });
  }

  setReportText = (rows: (string | number)[][]) => {
    let reportText = '';

    rows.forEach((rowArray) => {
      const row = rowArray.join(',');
      reportText = reportText.concat(`${row}\r\n`);
    });

    this.setState({ reportText });
  }

  toast = (err: boolean, msg: string) => {
    this.setState({
      showToast: true,
      toastError: err,
      toastMessage: msg,
    });

    setTimeout(() => {
      this.setState({ showToast: false });
    }, 5000);
  }

  render() {
    const {
      showToast,
      toastError,
      toastMessage,
      showLoader,
      reportText,
      orderErrors,
      subscriptionErrors,
    } = this.state;

    return (
      <div>
        <div className="text-center container-fluid">
          <p>
            Standard Orders Report contains all open orders (including subscriptions minus the
            details). Run the Subscribers Report to view all the details of active subscriptions.
            Be sure to fulfill orders every week to avoid seeing duplicate orders/data the
            following week. Customers report contains all active subscribers information
            and information for customers who placed a standard order.
          </p>

          <div>
            <button type="button" className="btn" onClick={this.getStandardOrdersReport}>
              Generate Standard Orders Report
            </button>
          </div>

          <div>
            <button type="button" className="btn" onClick={this.getQuantityReport}>
              Generate Quantity Report
            </button>
          </div>

          <div>
            <button type="button" className="btn" onClick={this.getCustomizableMealsReport}>
              Generate Customizable Meals Report
            </button>
          </div>

          <div>
            <button type="button" className="btn" onClick={this.getSubscribersReport}>
              Generate Subscribers Report
            </button>
          </div>

          <div>
            <button type="button" className="btn" onClick={this.getCustomersReport}>
              Generate Customers Report
            </button>
          </div>

          <textarea rows={20} style={{ width: '100%', marginTop: 25 }} value={reportText} readOnly />
        </div>

        <div className="container-fluid">
          <h3>Invalid Orders (Id)</h3>

          <ul>
            {orderErrors.map((elem) => (
              <li key={elem}>{elem}</li>
            ))}
          </ul>

          <h3>Invalid Subscriptions (Customer Email, Variant Title)</h3>

          <ul>
            {subscriptionErrors.map((elem) => (
              <li key={elem}>{elem}</li>
            ))}
          </ul>
        </div>

        {showLoader ? <Loader /> : null}

        <Toast
          show={showToast}
          error={toastError}
          message={toastMessage}
        />
      </div>
    );
  }
}

export default Reporting;

import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../components/Loader';
import Toast from '../components/Toast';
import DriverConfig from '../components/DriverConfigRouting';
import generateRoutes from '../RouteGenerator';

class Routing extends Component {
  state = {
    pickupAddresses: [],
    showLoader: false,
    selectedDrivers: [],
    showToast: false,
    warnings: [],
    toastError: false,
    toastMessage: '',
  };

  componentDidMount() {
    const req1 = axios.get('/shipping-zones');
    const req2 = axios.get('/pickup-addresses');

    axios.all([req1, req2])
      .then(axios.spread((zones, pickupAddresses) => {
        const rates = zones.data.shipping_zones[0].price_based_shipping_rates;
        const warnings = [];

        for (let i = 0; i < rates.length; i += 1) {
          if (rates[i].name.toLowerCase().indexOf('pickup') > -1) {
            const mappedLocation = pickupAddresses.data.addresses.find((element: any) => (
              element.zoneId === rates[i].id
            ));

            if (mappedLocation === undefined) {
              warnings.push(`${rates[i].name} does not have a mapped address. Add one in the Config tab.`);
            }
          }
        }

        this.setState({ pickupAddresses: pickupAddresses.data.addresses, warnings });
      }))
      .catch(() => {
        this.toast(true, 'An error occured while retrieving the pickup address mappings');
      });
  }

  generateRoutes = () => {
    const { selectedDrivers, pickupAddresses } = this.state;

    if (selectedDrivers.length === 0) {
      this.toast(true, 'Select at least one driver to route.');
      return;
    }

    this.setState({ showLoader: true });

    const req1 = axios.get('/recharge-subscriptions');
    const req2 = axios.get('/recharge-customers');
    const req3 = axios.get('/recharge-addresses');
    const req4 = axios.get('/shopify-orders');

    axios.all([req1, req2, req3, req4])
      .then(axios.spread((subscriptions, customers, addresses, orders) => {
        generateRoutes(subscriptions.data, customers.data, addresses.data, orders.data,
          pickupAddresses, selectedDrivers, (err: any) => {
            if (err) {
              this.setState({ showLoader: false });
              this.toast(true, err);
              return;
            }

            this.setState({ showLoader: false });
          });
      }))
      .catch(() => {
        this.toast(true, 'An error occured while creating the routes.');
        this.setState({ showLoader: false });
      });
  }

  driverSelectionChange = (selectedDrivers: any) => {
    this.setState({ selectedDrivers });
  }

  toast = (err: boolean, msg: string) => {
    this.setState({
      showToast: true,
      toastError: err,
      toastMessage: msg,
    });

    setTimeout(() => {
      this.setState({ showToast: false });
    }, 5000);
  }

  render() {
    const {
      warnings,
      showLoader,
      showToast,
      toastError,
      toastMessage,
    } = this.state;

    return (
      <div className="container-fluid">
        <h2>Select Drivers to Route</h2>

        <hr />

        <DriverConfig
          driverSelectionChange={this.driverSelectionChange}
          toast={this.toast}
        />

        <h2>Create Routes</h2>
        <hr />

        {warnings.length > 0 ? (
          <div>
            <h4>Warnings</h4>

            <ul>
              {warnings.map((warning) => (
                <li key={warning} className="route-warning">
                  {warning}
                </li>
              ))}
            </ul>
          </div>
        ) : null}

        <button type="button" className="btn" onClick={this.generateRoutes}>
          Generate Routes
        </button>

        {showLoader ? <Loader /> : null}
        <Toast
          show={showToast}
          error={toastError}
          message={toastMessage}
        />
      </div>
    );
  }
}

export default Routing;

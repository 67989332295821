import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Config from './containers/Config';
import Reporting from './containers/Reporting';
import Routing from './containers/Routing';

const Routes = () => (
  <Switch>
    <Route path="/config" exact component={Config} />
    <Route path="/reporting" exact component={Reporting} />
    <Route path="/routing" exact component={Routing} />
    <Redirect from="/" to="routing" />
  </Switch>
);

export default Routes;

import React, { Component } from 'react';
import Toast from '../components/Toast';
import DriverConfig from '../components/DriverConfig';
import PickupLocationConfig from '../components/PickupLocationConfig';

class Config extends Component {
  state = {
    showToast: false,
    toastError: false,
    toastMessage: '',
  };

  toast = (err: boolean, msg: string) => {
    this.setState({
      showToast: true,
      toastError: err,
      toastMessage: msg,
    });

    setTimeout(() => {
      this.setState({ showToast: false });
    }, 5000);
  }

  render() {
    const { showToast, toastError, toastMessage } = this.state;

    return (
      <div className="container-fluid">
        <h2>Driver Configuration</h2>

        <hr />

        <DriverConfig
          toast={this.toast}
        />

        <h2>Pickup Location Configuration</h2>

        <hr />

        <PickupLocationConfig
          toast={this.toast}
        />

        <Toast
          show={showToast}
          error={toastError}
          message={toastMessage}
        />
      </div>
    );
  }
}

export default Config;

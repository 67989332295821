import React, { Component } from 'react';
import axios from 'axios';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

const Driver = (props) => {
  const { driver, onClick, selected } = props;

  return (
    <div className="inline-icon driver">
      <p>
        {driver.name}
      </p>
      {selected
        ? <i className="fa fa-times" onClick={() => onClick(driver)} />
        : <i className="fa fa-plus" onClick={() => onClick(driver)} />
      }
    </div>
  );
};

class DriverConfig extends Component {
  state = {
    allDrivers: [],
    selectedDrivers: [],
  };

  componentDidMount() {
    const { toast } = this.props;

    axios.get('/drivers')
      .then((res) => {
        this.setState({ allDrivers: res.data.drivers });
      })
      .catch(() => {
        toast(true, 'An error occured while retrieving the drivers.');
      });
  }

  addDriver = (driver) => {
    const { selectedDrivers, allDrivers } = this.state;
    const { driverSelectionChange } = this.props;
    const selectedDriversCopy = selectedDrivers.slice();
    const allDriversCopy = allDrivers.slice();
    const index = allDriversCopy.findIndex(element => element.id === driver.id);

    selectedDriversCopy.push(driver);
    allDriversCopy.splice(index, 1);

    this.setState({
      selectedDrivers: selectedDriversCopy,
      allDrivers: allDriversCopy,
    });

    driverSelectionChange(selectedDriversCopy);
  }

  removeDriver = (driver) => {
    const { selectedDrivers, allDrivers } = this.state;
    const { driverSelectionChange } = this.props;
    const selectedDriversCopy = selectedDrivers.slice();
    const allDriversCopy = allDrivers.slice();
    const index = selectedDriversCopy.findIndex(element => element.id === driver.id);

    allDriversCopy.push(driver);
    selectedDriversCopy.splice(index, 1);

    this.setState({
      selectedDrivers: selectedDriversCopy,
      allDrivers: allDriversCopy,
    });

    driverSelectionChange(selectedDriversCopy);
  }

  render() {
    const { allDrivers, selectedDrivers } = this.state;

    return (
      <div className="md-grid">
        <div className="md-col-xs-6">
          <p>
            {'All Drivers'}
          </p>

          <div className="driver-list">
            <TransitionGroup>
              {allDrivers.map(driver => (
                <CSSTransition
                  key={driver.id}
                  classNames="list-element-transition"
                  timeout={{ enter: 500, exit: 300 }}
                >
                  <Driver
                    driver={driver}
                    onClick={this.addDriver}
                    selected={false}
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
        </div>

        <div className="md-col-xs-6">
          <p>
            {'Selected Drivers'}
          </p>

          <div className="driver-list">
            <TransitionGroup>
              {selectedDrivers.map(driver => (
                <CSSTransition
                  key={driver.id}
                  classNames="list-element-transition"
                  timeout={{ enter: 500, exit: 300 }}
                >
                  <Driver
                    driver={driver}
                    onClick={this.removeDriver}
                    selected
                  />
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
        </div>
      </div>
    );
  }
}


Driver.propTypes = {
  driver: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

DriverConfig.propTypes = {
  toast: PropTypes.func.isRequired,
  driverSelectionChange: PropTypes.func.isRequired,
};

export default DriverConfig;
